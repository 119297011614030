import { ModuleWithProviders, NgModule } from "@angular/core";
import { DeloitteAiEnvironment, DELOITTEAI_ENVIRONMENT } from "./api";

@NgModule({
    imports: []
})
export class DeloitteAiEnvironmentModule {

    static forRoot(environment: DeloitteAiEnvironment): ModuleWithProviders<DeloitteAiEnvironmentModule> {

        const env: DeloitteAiEnvironment = {
            ...environment,
            MSIE: (window.document as any).documentMode
        };

        return {
            ngModule: DeloitteAiEnvironmentModule,
            providers: [{
                provide: DELOITTEAI_ENVIRONMENT,
                useValue: environment
            }]
        };
    }
}
