import { InjectionToken } from "@angular/core";

export interface DeloitteAiEnvironment {
    [key: string]: string | number | boolean | DeloitteAiEnvironment;

    context: "store" | "asset_owner_portal" | "landing";

    BRAIN_BASE_URL: string;
}

export const DELOITTEAI_ENVIRONMENT = new InjectionToken<DeloitteAiEnvironment>("DeloitteAi Environment");
