import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { environment } from "./environments/environment";
import { AOP_BASE_URL, BRAIN_BASE_URL, ENTILE_BASE_URL } from "@asset-owner-portal/api/tokens";
import { DELOITTEAI_ENVIRONMENT } from "@shared/environment";

if (environment.production) {
  enableProdMode();
}

function MyModuleFactory(): Promise<any> {
  const isMsIE = (window.document as any).documentMode;

  return !isNaN(isMsIE)
    ? import("@shared/page_internet_explorer/ie.module").then((module) => module.IEModule)
    : import("./app/app.module").then((module) => module.AppModule);
}

MyModuleFactory().then((module) => {
    platformBrowserDynamic([{
        /** @todo remove deprecated use DELOITTEAI_ENVIRONMENT */
        provide: AOP_BASE_URL,
        useValue: environment.BASE_AOP_URL
    }, {
        /** @todo remove deprecated use DELOITTEAI_ENVIRONMENT */
        provide: ENTILE_BASE_URL,
        useValue: environment.BASE_ENTILE_URL
    }, {
        /** @todo remove deprecated use DELOITTEAI_ENVIRONMENT */
        provide: BRAIN_BASE_URL,
        useValue: environment.BRAIN_BASE_URL
    }, {
        provide: DELOITTEAI_ENVIRONMENT,
        useValue: {
            context: "asset_owner_hub",
            ...environment
        }
    }])
    .bootstrapModule(module).catch(err => console.error(err));
});
